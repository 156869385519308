import {
  breakpointXsMax,
  breakpointSmMin,
  breakpointMdMin,
  breakpointLgMin,
  breakpointLgMax,
} from '@mediahuis/chameleon-theme-wl/tokens';
import type { FC } from 'react';

import type { ImageSizes, TeaserImageFormat } from '@hubcms/domain-images';
import { type PerViewport, type Viewport } from '@hubcms/domain-styling';
import type { TeaserImage } from '@hubcms/domain-teaser';
import { createImageSrcSet } from '@hubcms/utils-images';

import { getImageDetails } from '../../utils/getImageDetails';

import { NativeTeaserSimpleImage } from './NativeTeaserSimpleImage';
import { useResponsiveImage } from './useResponsiveImage';

const breakpoints: Record<Viewport, string> = {
  xs: breakpointXsMax,
  sm: breakpointSmMin,
  md: breakpointMdMin,
  lg: breakpointLgMin,
  xl: breakpointLgMax,
};

type NativeTeaserImageProps = {
  image: TeaserImage | PerViewport<TeaserImage>;
  format: TeaserImageFormat | PerViewport<TeaserImageFormat>;
  sizes?: ImageSizes;
  isPriority?: boolean;
  className?: string;
};

export const NativeTeaserImage: FC<NativeTeaserImageProps> = ({ image, sizes, format, className, isPriority = false }) => {
  const responsiveImage = useResponsiveImage({ image, format });
  if (!responsiveImage.isValid) {
    return null;
  }

  if (responsiveImage.isSimpleImage) {
    const { simpleFormat, simpleImage } = responsiveImage;
    return (
      <NativeTeaserSimpleImage
        format={simpleFormat}
        image={simpleImage}
        isPriority={isPriority}
        sizes={sizes}
        className={className}
      />
    );
  }

  const { responsiveProps, smallest } = responsiveImage;

  return (
    <picture>
      {responsiveProps.map(({ viewport, format: responsiveFormat, image: responsiveImage }) => {
        const { url } = getImageDetails(responsiveImage, responsiveFormat);
        return <source key={viewport} srcSet={createImageSrcSet(url)} media={`(min-width: ${breakpoints[viewport]})`} />;
      })}
      <NativeTeaserSimpleImage format={smallest.format} image={smallest.image} isPriority={isPriority} className={className} />
    </picture>
  );
};

export default NativeTeaserImage;
