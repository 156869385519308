import type { TeaserImageFormat } from '@hubcms/domain-images';
import { type PerViewport, type Viewport, viewPorts } from '@hubcms/domain-styling';
import type { TeaserImage } from '@hubcms/domain-teaser';
import { error } from '@hubcms/utils-monitoring';

type UseResponsiveImageProps = {
  image: TeaserImage | PerViewport<TeaserImage>;
  format: TeaserImageFormat | PerViewport<TeaserImageFormat>;
};

type InvalidImageResult = {
  isValid: false;
};
type SimpleImageResult = {
  isValid: true;
  isSimpleImage: true;
  simpleFormat: TeaserImageFormat;
  simpleImage: TeaserImage;
};
type ResponsiveImageResult = {
  isValid: true;
  isSimpleImage: false;
  responsiveProps: Array<{ viewport: Viewport; image: TeaserImage; format: TeaserImageFormat }>;
  smallest: { image: TeaserImage; format: TeaserImageFormat };
};

type UseReponsiveImageResult = InvalidImageResult | SimpleImageResult | ResponsiveImageResult;

export function useResponsiveImage({ image, format }: UseResponsiveImageProps): UseReponsiveImageResult {
  if (typeof format !== 'object' && 'imageFormats' in image) {
    return {
      isValid: true,
      isSimpleImage: true,
      simpleFormat: format,
      simpleImage: image,
    };
  }

  if (
    (typeof format === 'object' && Object.keys(format).length === 0) ||
    (!('imageFormats' in image) && Object.keys(image).length === 0)
  ) {
    error(`An image format or image object without any keys was passed.`);
    return {
      isValid: false,
    };
  }

  const formatPerViewport = typeof format === 'object' ? format : { xs: format };
  const imagePerViewport = !('imageFormats' in image) ? image : { xs: image };

  const viewPortsWithImageOrFormat = viewPorts
    .filter(viewport => formatPerViewport[viewport] || imagePerViewport[viewport])
    .map(viewport => ({
      viewport,
      image: getClosestValue(imagePerViewport, viewport),
      format: getClosestValue(formatPerViewport, viewport),
    }));
  const smallestViewport = viewPortsWithImageOrFormat[0];

  return {
    isValid: true,
    isSimpleImage: false,
    smallest: {
      format: smallestViewport.format,
      image: smallestViewport.image,
    },
    responsiveProps: viewPortsWithImageOrFormat.slice(1).reverse(),
  };
}

function getClosestValue<T>(source: PerViewport<T>, viewport: Viewport): T {
  if (source[viewport] !== undefined) {
    return source[viewport];
  }
  const closestSmallerViewport = viewPorts
    .slice(0, viewPorts.indexOf(viewport))
    .reverse()
    .find(candidate => !!source[candidate]);

  if (closestSmallerViewport) {
    return source[closestSmallerViewport]!;
  }
  const closestLargerViewport = viewPorts.slice(viewPorts.indexOf(viewport) + 1).find(candidate => !!source[candidate]);
  // We can be certain that a next viewport exists if the current does not exist or the previous doesn't exist
  return source[closestLargerViewport!]!;
}
